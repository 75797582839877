<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col md="4" lg="4" xl="3" class="d-none d-md-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper d-flex justify-center">
            <div class="d-flex align-center px-10 px-lg-12 flex-column justify-center flex-grow-1">
              <v-img
                contain
                max-width="453px"
                width="100%"
                class="flex-grow-0 mb-5"
                :src="require(`@/assets/images/login/login_illustration_experts.svg`)"
              ></v-img>
              <p class="text-center accent--text text-xl">bAV Portal</p>
            </div>
          </div>
        </v-col>
        <v-col md="8" lg="8" xl="9" cols="12" class="d-flex auth-bg-v2 pb-0 flex-column">
          <div class="d-flex flex-column">
            <AppBarI18n />
          </div>
          <div class="d-flex flex-grow-1 align-center">
            <v-card class="auth-card" color="transparent">
              <v-card-title class="d-flex align-center justify-center py-7">
                <v-img :src="appLogo" max-width="375px" alt="logo" contain></v-img>
              </v-card-title>
              <v-card-text v-if="!success" :class="codeSent ? 'pb-0' : ''">
                <p class="font-weight-bold text--secondary mb-3 text-left text-xl">
                  {{ $t('aut2faSetupHeading') }}
                </p>
                <p v-if="!codeSent" class="mb-0 text-sm">
                  {{ $t('aut2faSetupIntro') }}
                </p>
              </v-card-text>

              <!-- 1st step -->
              <v-card-text v-if="!success" :class="codeSent ? 'd-sr-only' : ''">
                <v-form ref="twoFactorForm" v-model="isNumberValid" @submit.prevent="sendConfirmCode">
                  <vue-tel-input-vuetify
                    v-if="!codeSent"
                    v-model="phone"
                    validate-on-blur
                    :label="$t('twoFactorMobileNumberLabel')"
                    defaultCountry="DE"
                    :readonly="codeSent"
                    :rules="[validators.required, validators.phoneNumberValidator]"
                    :valid-characters-only="true"
                    :inputOptions="{ showDialCode: true }"
                    outlined
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </vue-tel-input-vuetify>
                  <v-btn
                    v-if="!codeSent"
                    :disabled="!phone || codeSent"
                    block
                    depressed
                    color="primary"
                    class="mt-3"
                    type="submit"
                  >
                    {{ $t('buttonConfirm') }}
                  </v-btn>
                </v-form>
              </v-card-text>

              <!-- 2nd step -->
              <v-card-text v-if="success" class="text-center">
                <v-avatar size="70" color="success" class="mb-5 v-avatar--dialog mt-1">
                  <v-icon color="white" size="40">
                    {{ icons.mdiCheckBold }}
                  </v-icon>
                </v-avatar>

                <p class="text-body-1 text-center mb-7">
                  {{ $t('twoFactorSuccessMsg') }}
                </p>

                <v-btn color="primary" depressed block @click="onComplete">
                  {{ $t('auth2faToPortal') }}
                </v-btn>
              </v-card-text>
              <v-card-text v-if="codeSent && !success" class="">
                <v-card-text class="pl-0 pr-0">
                  <p v-if="timeLeft === 0" class="text-sm mb-0 error--text">
                    {{ $t('auth2faSetupExpired1') }}
                    {{ $t('auth2faSetupExpired2') }}
                  </p>
                  <p v-else class="text-sm mb-0">
                    {{ $t('auth2faSetupInstructions1') }}.
                    {{ $t('auth2faSetupInstructions2') }}
                    <strong>{{ timeLeft }} </strong>
                    {{ $t('auth2faSetupInstructions3') }}
                  </p>
                </v-card-text>
                <v-form @submit.prevent="confirm">
                  <v-text-field
                    v-model="code"
                    outlined
                    :error="invalidCode"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :label="$t('auth2faConfirmCodePlaceholder')"
                    :placeholder="$t('auth2faConfirmCodePlaceholder')"
                    :append-icon="isPasswordVisible ? icons.mdiEye : icons.mdiEyeOutline"
                    hide-details="auto"
                    class="required"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                  <div class="d-flex align-center justify-end flex-wrap">
                    <button v-if="timeLeft === 0" class="text-sm primary--text mt-1" @click.prevent="resendCode">
                      {{ $t('auth2faSendCode') }}
                    </button>
                  </div>
                  <v-btn block depressed :disabled="!code" color="primary" type="submit" class="mt-3">
                    {{ $t('buttonConfirm') }}
                  </v-btn>
                </v-form>
              </v-card-text>

              <v-card-text v-if="!success">
                <div class="d-flex justify-center">
                  <button v-if="!codeSent" class="primary--text" @click.prevent="stepBack">
                    {{ $t('auth2faBackToLogin') }}
                  </button>
                  <button v-else class="primary--text" @click.prevent="stepBack">
                    {{ $t('auth2faBackToNumber') }}
                  </button>
                  <span v-if="!isForced" class="ml-2 mr-2">|</span>
                  <button v-if="!isForced" class="primary--text" @click.prevent="skip">
                    {{ $t('auth2faLater') }}
                  </button>
                </div>
              </v-card-text>

              <v-card-text v-if="!success" class="text-center flex-wrap mt-1 mb-3">
                <span class="mb-2 text-sm"> {{ $t('loginProblem') }} </span><br />
                <span>
                  <button
                    :disabled="!acceptedCookies"
                    class="support-link primary--text text-sm mt-1"
                    @click.prevent="openUserlike"
                  >
                    {{ $t('loginSupportLink') }}
                  </button>
                </span>
              </v-card-text>
            </v-card>
          </div>
          <Footer />
        </v-col>
      </v-row>
    </div>

    <!-- <ModalWindow :is-open="success" success>
      <template #content>
        <p class="font-weight-bold text--secondary mb-3 text-xl">
          {{ $t("twoFactorSuccessHeadline") }}
        </p>
        <p class="text-base">{{ $t("twoFactorSuccessMsg") }}</p>
      </template>
      <template #actions>
        <v-btn color="primary" depressed @click="onComplete">
          {{ $t("auth2faToPortal") }}
        </v-btn>
      </template>
    </ModalWindow> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, getCurrentInstance, watch, onMounted, onBeforeUnmount } from '@vue/composition-api';
import { required, phoneNumberValidator } from '@core/utils/validation';
import { mdiEyeOutline, mdiEyeOffOutline, mdiEye, mdiCheckBold } from '@mdi/js';
import themeConfig from '@themeConfig';
import ModalWindow from '../../components/modal/ModalWindow';
import { generate2faSecret, send2faConfirmCode, confirm2fa } from '../../api/fusion-auth';
import Footer from '@/components/footer/Footer.vue';
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18nLoggetOut.vue';

export default {
  components: { ModalWindow, Footer, AppBarI18n },
  setup() {
    const vm = getCurrentInstance().proxy;

    const twoFactorForm = ref(null);
    const step = ref(1);
    const timeLeft = ref(60);
    const phone = ref('');
    const code = ref('');
    const secret = ref('');
    const timer = ref(null);
    const codeSent = ref(false);
    const confirmed = ref(false);
    const isPasswordVisible = ref(false);
    const isNumberValid = ref(true);
    const invalidCode = ref(false);
    const success = ref(false);
    const openUserlike = () => {
      vm.$userlike.userlikeStartChat();
    };
    const acceptedCookies = ref(vm.$store.getters.necessaryCookies);
    const isForced = vm.$store.state.auth.companyData.settings2fa === 'FORCE';
    const user = vm.$store.getters.userData;

    const skip = () => {
      vm.$store.commit('setLoggedInStatus', true);
      vm.$router.push('/');
    };

    const stepBack = () => {
      if (codeSent.value) {
        phone.value = '';
        codeSent.value = false;
        clearInterval(timer.value);
      } else {
        vm.$router.go(-1);
      }
    };

    const generateSecret = () => {
      generate2faSecret().then((res) => {
        secret.value = res.data.secretBase32Encoded;
      });
    };

    const decreaseTimeLeft = () => {
      if (timeLeft.value === 0) {
        clearInterval(timer.value);
      } else {
        timeLeft.value -= 1;
      }
    };

    const resendCode = () => {
      timeLeft.value = 60;
      sendConfirmCode();
    };

    const sendConfirmCode = () => {
      invalidCode.value = false;

      if (timeLeft.value === 0) return;

      const isPhoneValid = twoFactorForm.value.validate();
      if (!isPhoneValid) return;

      clearInterval(timer.value);
      codeSent.value = true;
      timeLeft.value = 60;
      timer.value = setInterval(decreaseTimeLeft, 1000);

      const formattedPhone = phone.value.replace(/\s+/g, '');

      send2faConfirmCode(formattedPhone);
    };

    const confirm = () => {
      invalidCode.value = false;
      const formattedPhone = phone.value.replace(/\s+/g, '');

      confirm2fa(code.value, formattedPhone, secret.value)
        .then(() => {
          success.value = true;
          vm.$store.commit('setTwoFactorMethod', {
            method: 'sms',
            mobilePhone: formattedPhone,
          });
        })
        .catch(() => (invalidCode.value = true));
    };

    const onComplete = () => {
      success.value = false;
      vm.$store.commit('setLoggedInStatus', true);
      vm.$router.push('/');
    };

    onMounted(() => {
      generateSecret();
    });

    onBeforeUnmount(() => {
      clearInterval(timer.value);
      timer.value = null;
    });

    return {
      step,
      code,
      phone,
      confirmed,
      isPasswordVisible,
      isForced,
      timeLeft,
      timer,
      twoFactorForm,
      codeSent,
      isNumberValid,
      success,
      invalidCode,
      skip,
      stepBack,
      decreaseTimeLeft,
      sendConfirmCode,
      resendCode,
      onComplete,
      confirm,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiEye,
        mdiCheckBold,
      },
      validators: {
        required,
        phoneNumberValidator,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      openUserlike,
      acceptedCookies,
    };
  },
};
</script>

<style lang="scss">
.auth-v2 .v-input {
  &:not(.error--text) {
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input__control .v-text-field__details {
      display: none;
    }
  }
  &.error--text {
    *.country-code {
      .v-input__control .v-text-field__details {
        display: block;
      }
    }
  }
}
.vue-tel-input-vuetify {
  align-items: flex-start;
}
.vue-tel-input-vuetify .v-input--has-state {
  transform: translateY(0px);
}
</style>
